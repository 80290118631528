.create-new-game {
  cursor: pointer;
}

.new-game-modal-body {
  background-color: var(--blue);
  color: white;
  font-family: var(--form-font);
  padding: 20px 10px;
}

.new-game-modal-body form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.new-game-modal .modal-footer {
  background-color: var(--blue);
  color: white;
  font-family: var(--form-font);
  padding: 3px;
  display: flex;
  justify-content: space-between;
}

.location-parent {
  position: relative;
}

.location-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.972);
  color: var(--blue);
  padding: 2px;
}

.location-list-item {
  padding: 3px 10px;
  width: 100%;
  cursor: pointer;
}

.location-list-item:hover {
  background-color: rgba(162, 160, 160, 0.179);
}
