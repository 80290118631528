.history {
  width: 90%;
  margin-top: 50px;
  height: 100vh;
}

.history-wrapper {
  height: 90%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
}

.history .active-game {
  font-family: var(--form-font);
  background-color: rgb(100, 99, 99);
  color: white;
  border: 1px solid white;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  padding: 10px;
  height: 100%;
  max-height: 280px;
}

.history-game-score {
  width: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.history-game-score span {
  background-color: white;
  color: rgb(100, 99, 99);
  padding: 5px;
  border-radius: 2px;
  width: 40px;
}

@media screen and (max-width: 600px) {
  .history .active-game {
    width: 80%;
  }
}
