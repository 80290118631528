.player-modal {
  font-family: var(--form-font);
}

.player-modal .modal-content {
  background-color: transparent;
}

.players-joined {
  background-color: rgba(0, 0, 0, 0.644);
  color: white;
  padding: 10px;
  position: relative;
}

.players-joined .players-joined-item {
  padding: 2px 10px;
}

.players-joined .players-joined-item span {
  padding: 0 8px;
}

.player-modal .modal-footer {
  background-color: rgba(0, 0, 0, 0.644);
  padding: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.301);
}

.player-modal .modal-footer > .form-button:hover {
  background-color: transparent !important;
}
